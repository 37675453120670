export function render_advise(a:advise_type,compactview:boolean){
    var txt = ''
    if(a.text !== undefined){
	txt = a.text
    }
    if(compactview){
	return txt
    }
    var price = a.price
    var gain =  a.gain
    if( gain === undefined || gain === '') {
	gain = '?'
    }
    if( price === undefined || price === ''){
	price = '?'
    }
    return  (<span>{txt} <i>(Geschatte kosten: {price} ; Geschatte besparing: {gain})</i></span>)
}

export type advise_type = {
    gain: string;
    price: string;
    text?:string; // FIXME: should not be optional, in the end
};


