import * as React from 'react'
import QuotationsList from './QuotationsList'
import Expander_helper from './Expander_helper'
import ImplementationStep from './ImplementationStep'

interface MyProps {
    postid:number,
    expanded:boolean,
    options:any,
    permission:any,
    l1cluster:any,
    tup:any,
    l2cluster:any,
    quotations:Array<any>,
    bouwdeel:any,
    cb_parent:   (e: any, cb: (r: any) => void) => void
}
type MyState = {
}


class ChosenMeasure extends React.PureComponent<MyProps,MyState> {

    render_implementation(){
	let src = this.props.options.implementation
	let tup : {[k:string]:any} = {
	    attr_id: this.props.tup.attr_id,
	    label:'Uitvoering',
	    classes:[],
	    huidig : { },
	    voorstel: {},
	}

	for(var aspect of ['huidig','voorstel']){
	    tup[aspect].status = ''
	    tup[aspect].active = true
	    tup[aspect].bouwdeel = this.props.bouwdeel.slug
	    tup[aspect].options = src[aspect].options
	    tup[aspect].buttons = src[aspect].buttons


	    if(this.props.tup.implementation !== undefined){
		let asp = this.props.tup.implementation[aspect]
		tup[aspect].status = asp.winner
		tup[aspect].comments = asp.comments

	    }

	}
	if(this.props.tup.leverancier !== undefined){
	    //tup['leverancier'] = this.props.tup.leverancier
	    tup['supplier'] = this.props.tup.leverancier.winner
	}


	return (
    		<ImplementationStep
		    is_simple_block={false}
		    level2={this.props.l2cluster}
		    readonly={false}
		    level1={this.props.l1cluster}
		    permission={this.props.permission}
		    options={this.props.options}
		    postid={this.props.postid}
		    cb_parent={this.props.cb_parent}
		    tup={tup}
		    />
	)
	/*
	return (
	    <HdaStep 
	    post_id={this.props.postid.toString()}
	    options={this.props.options}
	    l2cluster={this.props.l2cluster}
	    permission={this.props.permission}
	    cb_parent={this.props.cb_parent}
	    tup={tup}

	    />

	)
	*/
    }
    render_quot_list_for_measure(){
	let measure = this.props.tup
	let attr_id = Number(measure.attr_id)
	let opt = this.props.options
	let qlist :Array<any> = []
	if(this.props.quotations !== undefined){
	    for(var q of this.props.quotations){
		if(Number(q.linked_attr) === attr_id){
		    qlist.push(q)
		}
	    }
	}
	return (
	    <div key={attr_id} className="chosen-measure">
	     <QuotationsList
		    expanded={this.props.expanded}
		    readonly={false}
		    measure={measure}
		    quotations={qlist}
		    l2cluster={this.props.l2cluster}
		    l1cluster={this.props.l1cluster}
		    permission={this.props.permission}
		    bouwdeel={this.props.bouwdeel}
		    options={opt}
		    postid={Number(this.props.postid)}
		    cb_parent={this.props.cb_parent}
		 />
	     </div>
	)
    }
    render(){
	let measure = this.props.tup
	let num = measure.autonum
	let label = (<span>Geselecteerde maatregel <i>#{num}: {measure.value.text}</i></span>)
	let exp = new Expander_helper()
	let collapsed = this.props.expanded ? "" : "collapsed"
	//this.render_quot_list_for_measure(attr_id))	return (
	return (
	    <div className={"quotations with-expand "+collapsed}>
	     	<div className="header" onClick={exp.toggle_expand}>
		    <div className="pre-icon"></div>
		    <div className="label">{label}</div>
		</div>
		<div className="body">
		    <div className="chosen-measure">
			{this.render_quot_list_for_measure()}
			{this.render_implementation()}
		    </div>
		</div>
	    </div>

	)
    }

}

export default ChosenMeasure

