import * as React from 'react';
import HdaStep from './HdaStep';
import QuotationsList from './QuotationsList'
import PotMeasuresList from  './PotMeasuresList'
import Expander_helper from './Expander_helper'
import ImplementationList from './ImplementationList'


interface MyProps {
    options:any,
    compactview:boolean,
    post_id:string,
    view:string,
    level1cluster:any,
    cb_parent: (e: any, cb: (r: any) => void) => void,
    permissions:any,
    stup:any,
    tup:any
}
type MyState = {
    measures: {[aid:string]:any}    
    new_measure: boolean  // after a new measure was added, to force expander
    is_loading:boolean
}
class Level2BuildingPart extends React.PureComponent<MyProps,MyState> {
    constructor(props: MyProps) {
        super(props)
	this.handle_cb = this.handle_cb.bind(this)
	this.state = {
	    is_loading:false,
	    new_measure:false,
	    measures:{}

	}
    }
    componentDidMount(){
	let tup = this.props.tup
	if(tup.tup.advises){
	    this.setState({ measures: tup.tup.advises})
	}
    }
    handle_cb(e:any, cb:(r:any)=>void){
	this.props.cb_parent(e,(r:any) => {
	    console.log('return from cb-parent')
	    if(r.bd_advise){
		let new_aid = r.value
		let new_tup = r.bd_advise[new_aid]
		if(new_tup !== undefined){
		    let tup : {[k:string]:any}= {}
		    tup[new_aid] = new_tup
		    this.setState(prevState => ({
			new_measure: true,
			measures: {...prevState.measures, ...tup}
		    }))
		}
	    }
	    cb(r)
	})

    }
    
    get_bouwdeel(){
	return {
	    label: this.get_buildingpart_label(),
	    slug: this.props.tup.slug
	}
    }
    get_l2cluster(){
	return {
	    name: this.props.stup.name,
	    label: this.props.stup.label
	}
    }
    is_expanded(){
	let name = this.props.tup.slug
	if(this.props.options.config !== null){
	    let conf = this.props.options.config
	    if(conf.bd !== undefined){
		return conf.bd === name
	    }
	}
	return false
    }

    /*
     *  list of Implementations not organised by PotMeasures  
     **/
    render_implementations(){
        if(this.props.options.config !== null){
            let conf = this.props.options.config
            if(conf.blocktype === "simple"){
                return
            }
        }
	let list = this.props.tup.tup.implementations 
	if(list.length === 0){return null}
        let opt = this.props.options.repeater_blocks
        opt.config = this.props.options.config

        let expand_this = opt.config.mtype === "implementation"

        return  <ImplementationList
                    expanded={expand_this}
                    context={"level2cluster"}
                    implementations={list}
                    l2cluster={this.get_l2cluster()}
                    l1cluster={this.props.level1cluster}
                    permission={this.props.permissions}
                    bouwdeel={this.get_bouwdeel()}
                    options={opt}
                    postid={Number(this.props.post_id)}
                    cb_parent={this.props.cb_parent}
                 />

    }

    /*
     *  list of Quotations not organised by PotMeasures  
     **/
    render_quotations(){
	let list = this.props.tup.tup.quotations.filter((x:any) => Number(x.linked_attr) === 0)
	let archive_mode = false // archive-mode
	if(this.with_potmeasures()){
	    archive_mode = true
	    // check if there are orphaned quotations
	    if(list.length === 0){
		return null
	    }
	}

	if(!this.props.level1cluster.classes.includes('l2quotations')){
	    return null
	}
	if(this.props.stup.classes !== undefined){
	    if(this.props.stup.classes.includes("no-l2quotations")){
		return null
	    }
	}
	let opt = this.props.options.repeater_blocks
	opt.config = this.props.options.config

	let expand_this = opt.config.mtype === "quotation"
	return  <QuotationsList
		    expanded={expand_this}
		    quotations={list}
		    l2cluster={this.get_l2cluster()}
		    l1cluster={this.props.level1cluster}
		    permission={this.props.permissions}
		    readonly={archive_mode}
		    bouwdeel={this.get_bouwdeel()}
		    options={opt}
		    postid={Number(this.props.post_id)}
		    cb_parent={this.props.cb_parent}
		 />

    }
    with_potmeasures(){
	if(this.props.view === "hdquotations"){return false}
	if(!this.props.level1cluster.classes.includes('l2potmeasures')){
	    return false
	}
	if(this.props.stup.classes !== undefined &&
	    this.props.stup.classes.includes('no-potmeasures')){
	    return false
	}
	return true
    }
    render_pot_measures(mode:string){
	if(! this.with_potmeasures()){
	    return null
	}
	if(mode === "choice"){
	    let l1classes = this.props.level1cluster.classes
	    if(! l1classes.includes('l2quotations')){
		return null
	    }

	}

	let opt = this.props.options.repeater_blocks
	opt.config = this.props.options.config
	// in deeplink situation, force expander state
	let expand_this = opt.config.mtype === "potmeasure"
	if(mode === "all" && this.state.new_measure){
	    expand_this=true
	}
	// react-key will update when state.measures is changed
	let react_key = mode + "-"+Object.keys(this.state.measures).join(",")
	let quo_list = mode === "all" ? [] : this.props.tup.tup.quotations
	let imp_list = mode === "all" ? [] : this.props.tup.tup.implementations

	
	return <PotMeasuresList key={react_key}
		   expanded={expand_this}
		   l2cluster={this.get_l2cluster()}
		   l1cluster={this.props.level1cluster}
		   permission={this.props.permissions}
		   bouwdeel={this.get_bouwdeel()}
		   measures={this.state.measures}
		   options={opt}
		   quotations={quo_list}
		   implementations={imp_list}
		   mode={mode}
		   postid={Number(this.props.post_id)}
		   cb_parent={this.handle_cb}
	/>

    }

    // l2tup : item from stup.fields
    render_l2_block(l2tup:any,j:number,l2c:any){
	if(this.props.view === "hdquotations"){return null}
	let show_in_building_measures = [ "oordeel","uitvoering_van"]
	if(this.props.view === "buildingmeasures" 
	   && ! show_in_building_measures.includes(l2tup.name)){
	    return null
	}
	if(this.props.stup.classes !== undefined){
	    let check = "no-"+l2tup.name
	    if(this.props.stup.classes.includes(check)){
		return null
	    }
	}
	let bd = this.props.tup
	let bdtup :  {[name:string]:any} = {
	    slug: this.props.tup.slug,
	    label: this.get_buildingpart_label()

	}
	if('tup' in bd){
	    let k = l2tup.key
	    console.log(` check if k ${k} in bd.tup`)
	    console.log(bd.tup)
	    if(k in bd.tup){
		console.log('yep')
		bdtup = bd.tup[k]
	    }
	}
	
	// bptup bevat data uit GET /buildingparts
	if(l2tup.classes === undefined
	  ||l2tup.classes === null){ return null  }

	if(l2tup.classes.includes('with-adviseur')){
	    console.log('class with adviseur')
	    let akey = this.get_key_adviseur()
	    if(akey){
		console.log(` key adviseur ${akey}`)
		//let zwakey = this.get_props.options.zw_adviseur.key
		bdtup.adviseur = bd.tup[akey]
	    }
	}
	console.log(bdtup)
	if(l2tup.classes.includes('verborgen')){
	    return null
	}

	return (
	    <HdaStep
	    options={this.props.options}
		l2cluster={l2c}
		bptup={bdtup}
	    cb_parent={this.props.cb_parent}
	    permission={this.props.permissions}
	    key={j} tup={l2tup} post_id={this.props.post_id} />
	)
    }
    get_key_adviseur(){
	for(var x of this.props.stup.fields){
	    if(x.name === 'adviseur'){
		return x.key
	    }
	}
    }
    /*
     *  part: (1 or 2)
     *    part=1: show all rows except with class bottom
     *    part=2: show only the rows with class bottom
     */
    render_l2_blocks(part:number){
	let stup = this.props.stup
	let l2c = { label : stup.label, name: stup.name}
	return (
	    <div className="steps">
		{stup.fields.map((tup:any, j:number) => {
		    if(tup.classes !== undefined && tup.classes.includes('bottom')){
			if(part == 1){ return null }
		    }else{
			if(part == 2){return null}
		    }
		    return this.render_l2_block(tup,j,l2c)
		})}
	    </div>
	)
    }

    get_choices(){
	let opt = this.props.options.repeater_blocks
	let buttons = opt.buildingparts.buttons
	let ret :{[name:string]:string}={}
	for(var x of buttons){
	    ret[x.name] = x.label
	}
	return ret
    }
    get_buildingpart_label(){
	let choices = this.get_choices()
	return choices[this.props.tup.slug]
    }
    render_main_body(){
	return (
	    <div>
		    {this.render_l2_blocks(1)}
		    {this.render_pot_measures('all')}
		    {this.render_pot_measures('choice')}
		    {this.render_quotations()}
		    {this.render_implementations()}
		    {this.render_l2_blocks(2)}
	    </div>
	)
    }
    render(){
	let label = this.get_buildingpart_label()
	let highlight = this.is_expanded() ? "highlight" : ""
	let exp = new Expander_helper()
	if(this.props.compactview){
	    return (
		<div className="buildingpart">
		  {this.render_main_body()}
		</div>
	    )
	}
	return (
	    <div className={"buildingpart "+highlight}>
		<div className="header">
		    <div className="pre-icon"></div>
		    <div className="label">Bouwdeel: {label}</div>
		</div>
		<div className="body">
		  {this.render_main_body()}
		</div>
	    </div>
		
	)

    }
}
export default Level2BuildingPart
