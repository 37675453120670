
import * as React from 'react'
import $ from 'jquery'

interface MyProps {  
    on_change:   (cmd:string) => void
}

type MyState = { 
    button1_is_enabled: boolean
    button2_is_enabled: boolean
}

class HdBlockListExpanderButtons extends React.PureComponent<MyProps,MyState> {
    constructor(props: MyProps) {
	super(props)
	this.handle_click_expand_all = this.handle_click_expand_all.bind(this)
	this.handle_click_unexpand_all = this.handle_click_unexpand_all.bind(this)

	this.state = {
	    button1_is_enabled:true,
	    button2_is_enabled:false
	}
    }
    handle_click_expand_all(e:any){
	this.props.on_change('expand-all')
	this.setState({ 
	    button1_is_enabled: false,
	    button2_is_enabled: true
	})
	$('.with-expand.collapsed').removeClass('collapsed');
    }
    handle_click_unexpand_all(e:any){
	this.props.on_change('unexpand-all')
	this.setState({
	    button1_is_enabled: true,
	    button2_is_enabled: false
	})
	$('.with-expand').addClass('collapsed');
    }
    toggle(){
	this.setState({
	    button1_is_enabled: true,
	    button2_is_enabled: true
	})

    }

    render(){
	let cls1 = this.state.button1_is_enabled ? "enabled" : "disabled"
	let cls2 = this.state.button2_is_enabled ? "enabled" : "disabled"
	return (
	    <div className="expander-buttons"> 
		<span className={"cf-button "+cls1} onClick={this.handle_click_expand_all}>Alles openklappen</span>
		<span className={"cf-button "+cls2} onClick={this.handle_click_unexpand_all}>Alles dichtklappen</span>
	    </div>
	)
    }

}

export default HdBlockListExpanderButtons
