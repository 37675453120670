import * as React from 'react'
import HdaStep from './HdaStep';
import Date_formatting from './Date_formatting'


interface MyProps {
    type:string
    row:any
    options:any
    permissions:any
    cb_parent:   (e: any, cb: (r: any) => void) => void    
}
type MyState = {
    adviseur: string
    coach:string
    coach_status:string
    status:string
}
class AdviesAanvraag  extends React.PureComponent<MyProps,MyState> {
    constructor(props: MyProps) {
        super(props)
	this.handle_cb = this.handle_cb.bind(this)
	this.state = {
	    adviseur:'',
	    coach:'',
	    coach_status:'',
	    status:''
	}
    }
    update_state(key:string, val:string){
	console.log(` update_state ${key} = ${val}`)
	if(key === 'zw-advies'){
	    this.setState({status:val})
	}
	else if(key === 'coach_invulhulp'){
	    this.setState({coach_status:val})
	}
    }
    // pass-on the signal from children
    handle_cb(e:any,cb:(r:any)=>void){
	console.log(e)
	if(e.cmd === "update-adviseur"){
	    this.setState({adviseur: e.value})
	}
	if(e.cmd === "set-action-status"){
	    this.update_state(e.acf_field.name, e.winner)
	}
	this.props.cb_parent(e,(r:any)=>{
	    // update via HdaVote popup
	    console.log(r)
	    if(r.winner_changed){
		this.update_state(r.acf_field.name, r.winner)
	    }
	    cb(r)
	})	
    }
    render_campagne_step(){
	if(this.props.type !== "zw-advies"){return null }
	let l2tup = this.props.row.campagne.step_tup
	return this.render_extra_hdblock(l2tup)
    }
    render_coach_help_step(){
	if(this.props.type !== "zw-advies"){return null }
	let l2tup = this.props.row.coach_help.step_tup
	return this.render_extra_hdblock(l2tup)
    }
    render_extra_hdblock(l2tup:any){
	let tup = this.props.row.tup
	let l2c = {
	    name: tup.level2
	}
	return (
		<HdaStep
		    options={this.props.options}
		    l2cluster={l2c}
		    cb_parent={this.handle_cb}
		    permission={this.props.permissions}
		    tup={l2tup} post_id={tup.post_id}
		/>

	)
    }
    render_step(){
	let tup = this.props.row.tup
	if(tup.attr_type === null){
	    //return
	}
	let l2c = {
	    name: tup.level2
	}
	let l2tup = this.props.row.advise.step_tup
	if(tup.level2 === 'NOT_LEVEL2'){
	    return (
		<HdaStep
		    options={this.props.options}
		    l2cluster={l2c}
		    cb_parent={this.handle_cb}
		    permission={this.props.permissions}
		    tup={l2tup} post_id={tup.post_id}
		/>
	    )
	}else{
	    let bdtup = {
		slug: tup.bouwdeel,
		huidig: {
		    row: this.props.row.attr,
		    comments: this.props.row.comments.huidig
		},
		adviseur: {
		    huidig: {
			row: l2tup.adviseur,
			comments:[]
		    }
		}
	    }
	    return (
		<HdaStep
		    options={this.props.options}
		    l2cluster={l2c}
		    cb_parent={this.handle_cb}
		    permission={this.props.permissions}
		    tup={l2tup} post_id={tup.post_id}
		    bptup={bdtup}
		/>
	    )
	}
    }
    render_contact_fields(fields:any){
	let labels : {[k:string]:string} = {
	    'fullname' : 'Naam',
	    'email' : 'Email',
	    'phone': 'Telefoon',
	}
	let is_empty = true
	for(var fkey of Object.keys(labels)){
	    let value = fields[fkey]
	    if(!(value === '' || value === null)){
		is_empty = false
	    }
	}
	if(is_empty){ return null }
	
	let prefix = this.props.type === "zw-advies" ? "ZW-advies aangevraagd door:" : "Aangevraagd door:"
	return (
	    <div className="field-cluster">
	    {prefix}
		    <div className="body">
	    	    {Object.entries(labels).map(([fkey,label]:[string,any],i:number) => {
	    		let fvalue = fields[fkey]
	    		if(fvalue === '' || fvalue === null) { return null }
	    		return (
	    		    <div key={i} className="field sub-field">
	    			<div className="xlabel">{label}:</div>
	    			<div className="value">{fvalue}</div>
	    		    </div>
	    		)
	    	    })}
		</div>
    	    </div>
	)
    }
    render_expand(){
	let row = this.get_key_values()
	let hd = this.props.row.hd
	let adres = (<span>{row.adres}</span>)
	if(hd.grouplink !== undefined){
	    let url = hd.grouplink + 'huisdossier/?viewpart=actionlist'

	    adres = (
		<a href={url} target="_blank"> { row.adres } </a>
	    )
	}

	let fields = {
	    adres : {label:'Adres',value : adres},
	    postcode: {label:'Postcode' ,value: row.postcode},
	    plaats: {label:'Plaats',value:row.plaats},
	    wijkgenoten: {label:'Aantal wijkgenoten in de groep',value:row.wijkgenoten},
	    invoer_todo: {label:'Aantal lege verplichte invulvelden',value:row.invoer_aantal_todo},
	    schilindicator: {label:'Schilindicator (warmteverlies / m²)', value:row.schilindicator},
	    status: {label:'advies-status',value:row.status},
	    date: {label:'Laatste wijziging',value: row.date},
	    adviseur: {label:'Toegewezen aan',value:row.adviseur},
	    level1: {label:'Topic',value: row.level1},
	    level2: {label:'Onderdeel',value:row.level2},
	    bd: {label:'Bouwdeel',value:row.bouwdeel},
	}
	let skip = ['--']
	if(this.props.type === 'zw-advies'){
	    fields.date.label = 'ZW-advies laatste wijziging'
	    fields.adviseur.label = 'ZW-adviseur'
	    skip.push('date')
	    skip.push('status')
	    skip.push('level1')
	    skip.push('level2')
	    skip.push('bd')
	}else{
	    skip.push('level1')
	    skip.push('level2')
	    skip.push('status')
	    skip.push('invoer_todo')
	    skip.push('wijkgenoten')
	    skip.push('schilindicator')
	}

	let cls = ""
	if(this.props.options.context === "adviesaanvragen"){
	    cls = this.props.options.adviestype
	}
	return (
	    <div className={"expand "+cls}>
		<div className="fields panel">
		{Object.entries(fields).map(([fkey,tup]:[string,any],i:number) => {
		    if(skip.includes(fkey)){ return null }
		    let fvalue = tup.value
		    if(fvalue === '' || fvalue === null) { return null }
		    return (
			<div key={i} className="field">
			    <div className="xlabel">{tup.label}:</div>
			    <div className="value">{fvalue}</div>
			</div>
		    )
		})}
		{this.render_contact_fields(row.contact)}
		</div>
		<div className="widgets">
		<div className="panel">
		   <div className="step-with-field">
		    {this.render_step()}
		    {this.props.type === "zw-advies" ? (
		    <div className="field-below">
		       <div className="xlabel">{fields.date.label} </div>
			<div className="value">{fields.date.value}</div>
		    </div>) : null}
		    </div>

		{this.render_coach_help_step()}
		{this.render_campagne_step()}
	        </div>
		</div>		
	    </div>
	)

    }
    row_click_handler(e:any){
	console.log('row-click')
	let qel = e.target.closest('.grid-row');

	let is_collapsed = qel.classList.contains('collapsed');
	
	console.log(' is collapsed ?  ' + (is_collapsed ? 'y' : 'n'))
	document.querySelectorAll('.aanvraag').forEach((x)=>x.classList.add('collapsed'))
	console.log(qel)
	if(is_collapsed){
	    qel.classList.remove('collapsed')
	}else{
	    qel.classList.add('collapsed')
	}
    }
    get_contact(){
	let row = this.props.row
	let con = row.hd.contact
	if(con == null){
	    return {
		first_name:'',
		fullname: '',
		last_name:'',
		phone:'',
		email:''
	    }
	}
	con.fullname = con.first_name + ' ' + con.last_name
	return con
    }
    componentDidMount(){
	let row = this.props.row
	let advv = row.advise.step_tup.adviseur.value
	if(this.props.type === "zw-advies"){
	    let coach_help = row.coach_help
	    let ass = coach_help.step_tup.assignable
	    this.setState( {
		adviseur: advv,
		coach_status: coach_help.step_tup.huidig.value,
		coach:ass.value,
		status: row.tup.value
	    })
	}else{
	    this.setState( {
		adviseur: advv,
		status: row.tup.value
	    })
	}
    }
    get_coach_label(){
	if(this.props.type !== "zw-advies"){return null }
	let row = this.props.row
	let coach_help = row.coach_help
	let ass = coach_help.step_tup.assignable
	let v = Number(ass.value)
	let choices = this.props.options.coaches
	let res = choices.filter((x:any) =>  x.id === v)
	if(res.length === 1){
	    return res[0].name
	}
	return ""
    }
    get_adviseur_label(){
	let slug = this.state.adviseur
	let choices = this.props.options.adviseur.buttons_huidig.list
	let res = choices.filter((x:any) =>  x.name === slug)
	if(res.length === 1){
	    return res[0].label
	}
	return slug
    }
   get_coach_status(){
	if(this.props.type !== "zw-advies"){return null }
	let row = this.props.row
	let coach_help = row.coach_help
	let step = coach_help.step_tup
	let val = this.state.coach_status
	let choices = step.huidig.choices
	if(val === 'kies' ){ return ''}
	return choices[val]
    }

    get_status_value(){
	let row = this.props.row
	let v = this.state.status
	let choices = row.advise.step_tup.huidig.choices
	if(choices === undefined){
	    return null
	}
	return choices[v]
    }
    get_bouwdeel(){
	let tup = this.props.row.tup
	if (tup.bouwdeel === "") {
	    return ""
	}
	return this.props.options.buildingparts[tup.bouwdeel]
    }
    get_key_values(){
	let con = this.get_contact()
	let row = this.props.row
	let ret : {[key:string]:string} = {
	    level1:'',
	    adres: row.hd.title,
	    date: row.date,
	    postcode: row.hd.postcode,
	    plaats: row.hd.plaats,
	    wijkgenoten: row.hd.aantal_wijkgenoot,
	    invoer_aantal_todo: row.hd.invoer_aantal_todo,
	    schilindicator: row.hd.schilindicator,
	    contact: con
	}
	if(this.props.type !== "zw-advies"){
	    ret.level1 = row.advise.level1.label
	}
	if(row.level1 !== undefined && row.level1 !== null){
	    ret.level1 = row.level1.label
	    let l2s = row.level1.level2_clusters
	    for(var x of l2s){
		if( x.name === row.tup.level2){
		    ret.level2 = x.label
		}
	    }
	}
	ret.bouwdeel = this.get_bouwdeel()
	ret.adviseur = this.get_adviseur_label()
	ret.status = this.get_status_value()
	ret.coach = this.get_coach_label()
	ret.coach_status = this.get_coach_status()

	ret.level2 = row.tup.level2
	// lookup l2 label for l2 name
	if(row.tup.level2 === 'NOT_LEVEL2'){
	    ret.level2 = row.advise.step_tup.label
	}

	return ret
    }
    render(){
	let row = this.get_key_values()
	let date_num :number = Date.parse(row.date)
	let df = (new Date_formatting()).get_formatted_date(date_num)

	//'adres','wijkgenoten', 'invoer_aantal_todo','status','date', 'adviseur'
	let is_zw_adv = this.props.type === 'zw-advies'
	let keys = [ 'adres', 'level1',  'status','adviseur']
	if(this.props.type === "zw-advies"){
	    keys = [ 'adres',  'wijkgenoten', 'invoer_aantal_todo', 'status','adviseur','coach','coach_status']
	}
	let date_num_str = isNaN(date_num) ? "1" : date_num.toString()
	    
	return (
	    <div  className="aanvraag grid-row collapsed">
		<div className="row" onClick={this.row_click_handler}>
		    <div className="pre-icon"></div>
		    {keys.map((k:string) => (
			<div className={k} title={row[k]}>{row[k]}</div>
		    ))}
		    <div className="date" title={date_num_str}>{df}</div>
		</div>
		{this.render_expand()}
	    </div>
	)
    }
}

export default AdviesAanvraag
