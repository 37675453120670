import * as React from 'react'
import Loco from './Loco'
import QuotationStep from './QuotationStep'
import ImplementationStep from './ImplementationStep'
import Expander_helper from './Expander_helper'
import Date_formatting from './Date_formatting'


interface MyProps {
    options:any,
    permissions:any,
    row:any,
    cb_parent:   (e: any, cb: (r: any) => void) => void    
}
type MyState = {
    supplier: string
    status_huidig:string,
    status_voorstel:string
}
class QuotationsAndCustomersRow extends React.PureComponent<MyProps,MyState> {
    constructor(props: MyProps) {
        super(props)
	this.handle_cb = this.handle_cb.bind(this)
	this.state = {
	    supplier:'',
	    status_huidig:'',
	    status_voorstel:''
	}
    }
    // pass-on the signal from children
    handle_cb(e:any,cb:(r:any)=>void){
	console.log(e)
	if(e.cmd == "update-supplier"){
	    this.setState({supplier: e.value})
	}
	if(e.cmd == "set-quotation-status"){
	    if(e.winner){
		if(e.aspect === 'huidig'){
		    this.setState({status_huidig:e.winner})
		}
		if(e.aspect === 'voorstel'){
		    this.setState({status_voorstel:e.winner})
		}
	    }
	}
	this.props.cb_parent(e,(r:any)=>{
	    console.log(r)
	    if(r.blocktype === "quotation-status"){
		if(r.aspect === "huidig"){
		    this.setState({status_huidig: r.winner})
		}
		if(e.aspect === 'voorstel'){
		    this.setState({status_voorstel:r.winner})
		}
	    }
	    cb(r)
	})	
    }
    
    row_click_handler(e:any){
	console.log('row-click')
	let qel = e.target.closest('.grid-row');

	let is_collapsed = qel.classList.contains('collapsed');
	
	console.log(' is collapsed ?  ' + (is_collapsed ? 'y' : 'n'))
	document.querySelectorAll('.grid-row').forEach((x)=>x.classList.add('collapsed'))
	console.log(qel)
	if(is_collapsed){
	    qel.classList.remove('collapsed')
	}else{
	    qel.classList.add('collapsed')
	}
    }
    get_supplier_label(val:string){
	let options = this.props.options.leverancier.buttons
	let found = options.filter((x:any) => x.name === val)
	if(found.length === 1){
	    return found[0].label
	}
	return ""
    }
    componentDidMount(){
	let row = this.props.row
	this.setState({
	    supplier:row.supplier,
	    status_huidig: row.huidig.status,
	    status_voorstel: row.voorstel.status
	})
    }
    get_contact(){
	let row = this.props.row
	let con = row.hd.contact
	if(con == null){
	    return {
		first_name:'',
		fullname: '',
		last_name:'',
		phone:'',
		email:''
	    }
	}
	con.fullname = con.first_name + ' ' + con.last_name
	return con
    }
    get_key_values(){
	let row = this.props.row
	let con = this.get_contact()
	let bd_label = ''
	for(var x of this.props.options.buildingparts.buttons){
	    if(x.name === row.bouwdeel){
		bd_label = x.label
	    }
	}

	return {
	    date: row.last_change,
	    contact: this.get_contact(),
	    supplier: this.get_supplier_label(this.state.supplier),
	    adres: row.hd.title,
	    plaats: row.hd.plaats,
	    postcode: row.hd.postcode,
	    mobiel: con.mobiele_telefoon,
	    email: con.email,
	    bouwdeel: bd_label,
	    level1: row.cluster.level1.label,
	    level2: row.cluster.level2.label,
	    status1: this.get_status_label(this.state.status_huidig,'huidig'),
	    status2: this.get_status_label(this.state.status_voorstel,'voorstel')
	}
    }
    render_contact_fields(fields:any){
	let labels : {[k:string]:string} = {
	    'fullname' : 'Naam',
	    'email' : 'Email',
	    'phone': 'Telefoon',
	}
	let is_empty = true
	for(var fkey of Object.keys(labels)){
	    let value = fields[fkey]
	    if(!(value === '' || value === null)){
		is_empty = false
	    }
	}
	if(is_empty){ return null }
	return (
	    <div className="field-cluster">
		Aanvrager:
		    <div className="body">
	    	    {Object.entries(labels).map(([fkey,label]:[string,any],i:number) => {
	    		let fvalue = fields[fkey]
	    		if(fvalue === '' || fvalue === null) { return null }
	    		return (
	    		    <div key={i} className="field sub-field">
	    			<div className="xlabel">{label}:</div>
	    			<div className="value">{fvalue}</div>
	    		    </div>
	    		)
	    	    })}
		</div>
    	    </div>
	)
    }
    
    render_expand(){
	let val = this.get_key_values()
	let hd = this.props.row.hd

	let adres = (<span>{val.adres}</span>)
	if(hd.grouplink !== undefined){
	    let url = hd.grouplink + 'huisdossier/?viewpart=actionlist'

	    adres = (
		<a href={url} target="_blank"> { val.adres } </a>
	    )
	}
	
	let fields = {
	    'Adres' : adres,
	    'Postcode': val.postcode,
	    'Laatste wijziging': val.date,
	    //'Leverancier': val.supplier,
	    //'Plaats' : val.plaats,
	    //'Mobiel': val.mobiel,
	    //'Email': val.email,
	    'Betreft': val.level1,
	    'Bouwdeel': val.bouwdeel,
	    'Onderdeel': val.level2
	}
	let exp = new Expander_helper();
	
	return (
	    <div className="expand">
		<div className="fields panel">
		    {Object.entries(fields).map(([fname,fvalue]:[string,any],i:number) => {
			if(fvalue === "" || fvalue === undefined){return null}
			return (
			    <div key={i} className="field">
				<div className="xlabel">{fname}:</div>
				<div className="value">{fvalue}</div>
			    </div>)
		    })}
		{this.render_contact_fields(val.contact)}

		</div>
		<div className="widgets">
		    <div className="qstatus panel">
			{this.render_qstep()}
		    </div>
		    <div className="implementation panel">
			{this.render_implementations()}
		    </div>
		</div>
	    </div>
	)
    }
    render_implementations(){
	let row = this.props.row
	let opt = this.props.options
	let perm = this.props.permissions
	let actiestappen = {}
	if(row.implementation.huidig === undefined){
	    return null
	}
	return (
	    <ImplementationStep
		    is_simple_block={false}
		    readonly={false}
		    level1={row.cluster.level1}
		    level2={row.cluster.level2}
		    permission={this.props.permissions}
		    options={this.props.options}
		    postid={row.post_id}
		    cb_parent={this.handle_cb}
		    tup={row.implementation}
		/>

	)
    }
    render_qstep(){
	let row = this.props.row
	let measure = null
	if(row.linked_advise_value !== undefined){
	    measure = { value: row.linked_advise_value }
	}
	let opt = this.props.options
	return (
	    <QuotationStep
		is_simple_block={false}
		readonly={false}
		permission={this.props.permissions}
		level1={row.cluster.level1}
		level2={row.cluster.level2}
		measure={measure}
		options={opt}
		postid={row.post_id}
		cb_parent={this.handle_cb}
		tup={row} />
	)
    }
    get_status_label(val:string, aspect:string){
	let choices = this.props.options.quotation[aspect].options.choices
	if(val !== null){
	    return choices[val];
	}
    }
    render(){
	let row = this.props.row
	//let cluster_l1 = row.cluster.level1.label
	//let cluster_l2 = row.cluster.level2.label
	//let supp = this.get_supplier_label(row.supplier)
	//let con = this.get_contact()
	let single_supplier_mode = this.props.options.config.single_supplier_mode

	let val = this.get_key_values()
	let date_num :number = Date.parse(val.date)
	let df = (new Date_formatting()).get_formatted_date(date_num)

	let q = this.props.options.query
	let skip_level2 = false
	if(q.list !== undefined && q.list.includes("opwek")){
	    skip_level2 = true
	}

	    return (
		<div  className=" grid-row collapsed">
		    <div className="row" onClick={this.row_click_handler}>
			<div className="pre-icon"></div>
			<div className="adres" title={val.adres}>{val.adres}</div>
			{skip_level2 ? null : (
			<div className="level2"   title={val.level2}> {val.level2}</div>
			)}
			<div className="status" title={val.status1}>{val.status1}</div>
			<div className="status2" title={val.status2}>{val.status2}</div>
			{single_supplier_mode? null : (
			<div className="supplier" title={val.supplier}>{val.supplier}</div>
			)}
			<div className="date" title={date_num.toString()}>{df}</div>

		    </div>
		    
		    {this.render_expand()}
		    
		</div>
	    )
    }
}

export default QuotationsAndCustomersRow
