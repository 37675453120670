import * as React from 'react'


import Loader from './Loader'
import Loco from './Loco'
import QuotationStep from './QuotationStep'
import Expander_helper from './Expander_helper'
import WpApi from './wpapi'


interface MyProps {
    postid:number,
    options:any,
    readonly:boolean,
    measure?:any,
    permission:any,
    l1cluster:any,
    expanded:boolean,
    l2cluster:any,
    bouwdeel:any,
    quotations:Array<any>,
    cb_parent:   (e: any, cb: (r: any) => void) => void
}
type MyState = {
    is_loading:boolean,
    quotations:Array<any>,
}

class QuotationsList extends React.PureComponent<MyProps,MyState> {
    constructor(props: MyProps) {
        super(props)

	this.state = {
	    is_loading:false,
	    quotations:[]
	}
	
    }
    componentDidMount(){
	let quotations = this.props.quotations
	if(quotations.length > 0){
	    this.setState({'quotations': quotations})
	}
    }
    clicked_add_quotation(){
	let bdslug = this.props.bouwdeel === null ? "hele_woning" : this.props.bouwdeel.slug
	if(this.props.l2cluster.name === undefined ){
	    console.log("ERROR l2cluster not defined")
	    return 
	}

	let d = {
	    post_id: this.props.postid,
	    measure_id: null,
	    acf_key: this.props.l1cluster.key,
	    votetype: 'quotation',
	    level2: this.props.l2cluster.name,
	    bouwdeel: bdslug,
	    value: this.props.l2cluster.name
	}
	if(this.props.measure !== undefined){
	    d.measure_id = this.props.measure.attr_id
	}
	this.setState({is_loading:true})

	WpApi.do_post('/hd-attr',d, (r) => {
	    r.status = 'kies'
	    r.uistate = 'new'
	    console.log(r);
	    if(r.huidig !== undefined){
		this.setState(prevState => ({
		    is_loading:false,
		    quotations: [...prevState.quotations, r]
		}))
	    }
	})

    }
    render_quotations(){
	let l2names = [ this.props.l2cluster.name ]
	if(this.props.l1cluster.classes.includes('quot-archive-wtw')){
	    l2names = [
		'los_element', 'met_convector','hele_woning'
	    ]
	    
	}
	return (
	    <div className="the-list">
		{this.state.quotations.map((tup:any,i:number) => {
		    if(l2names.includes(tup.level2)){
			//return (<div>YUP </div>)
			return <QuotationStep
				   key={i}
				   is_simple_block={false}
				   readonly={this.props.readonly}
				   measure={this.props.measure}
				   level2={this.props.l2cluster}
				   level1={this.props.l1cluster}
				   permission={this.props.permission}
				   options={this.props.options}
				   postid={this.props.postid}
				   cb_parent={this.props.cb_parent}
				   tup={tup}
			       />
		    }
		})}
	    </div>
	)
    }

    render(){
	let classes = this.props.expanded ? "highlight" : "collapsed"
	let txt2 = "Offertes"
	if(this.props.readonly){
	    txt2 = "ARCHIEF (zonder maatregelkeuze): Offertes"
	    classes += " archive"
	}
	if(this.props.measure !== undefined){
	    txt2 = "Offertes voor deze maatregel, "
	}
	let bdlabel = this.props.bouwdeel === null ? "" :  `(voor bouwdeel ${this.props.bouwdeel.label})`
	let cluster_label = this.props.l2cluster.label === undefined 
	    ? this.props.l1cluster.label 
	    : this.props.l2cluster.label
	
	let label_txt = (<span> {txt2} voor: <i>{cluster_label} {bdlabel}</i></span>)
	let exp = new Expander_helper()
	//let add_txt = Loco.tr('add_quotation')
	let add_txt = 'Voeg een Offerte toe'
	return (
	    <div className={"quotations with-expand "+classes}>
		<div className="header" onClick={exp.toggle_expand}>
		    <div className="pre-icon"></div>
		<div className="label">{label_txt}</div>
		</div>
		<div className="body">
		    {this.render_quotations()}
		    {this.props.readonly ? null : (
		    <div className="single-button">
		    {this.props.permission.hd_member_status.is_aspirantlid ?  (
			<span className="cf-button"
			      onClick={()=>this.clicked_add_quotation()}>{add_txt}</span>
		    ) : (
			<div>
			    <span className="cf-button-disabled">{add_txt}</span>
			    <div>(offertes kunnen pas worden aangevraagd na het zw-advies)</div>
			</div>
		    )}

		    </div>
		    )}
		</div>
	    </div>
	)

    }
}
export default QuotationsList
