import * as React from 'react';

import HdaStep from './HdaStep';
import Level2Clusters from "./Level2Clusters"
import DerivedValueStep from "./DerivedValueStep"
import V1Inputs from "./V1Inputs"
import SubSection from "./SubSection"
import WpApi from './wpapi'
import Expander_helper from './Expander_helper'
import QuotationsList from './QuotationsList'

interface MyProps {
    post_id: string,
    user:any,
    tup: any,
    options:any,
    permissions:any,
    view:string,
    cb_parent:   (e: any, cb: (r: any) => void) => void
}


type MyState = {
    quotations:Array<any>,
    is_loading:boolean,
    comment_count:number,
    comments: Array<string>,
    completed:boolean
};

interface cb_after_vote {
    (r:any): void;
}

class HdaSection extends React.PureComponent<MyProps,MyState> {

    private current_row_key = "-";
    private callback_after_vote :cb_after_vote = (x)=>{ console.log("dummy")}

    constructor(props: MyProps) {
	super(props)
	this.handle_cb = this.handle_cb.bind(this)
	this.handle_info_click = this.handle_info_click.bind(this)
	this.handle_comment_click = this.handle_comment_click.bind(this)
	this.toggle_expand = this.toggle_expand.bind(this)

	this.state = {
	    quotations:[],
	    is_loading:false,
	    comment_count:0,
	    comments:[],
	    completed:false
	}
    }


    // send signal to parent ; to open the vote-grid
    public handle_cb(e:any, cb:(r:any)=>void){
	this.callback_after_vote = cb;
	console.log("handle cb")
	/*
	    //if(e.cmd === "set-action-status-xxx"){
	    //  console.log(e)
	    //   this.setState({ completed : (e.section_status === "ready")})
	    */
	if(e.cmd === "set-quotation-status"){
	    console.log(e)
	    console.log(`quotation-status: ${e.value}`)
	    let d = {
		post_id : this.props.post_id,
		votetype: 'choice-on-attr',
		value: e.value,
		acf_key: e.acf_key,
		aspect: 'huidig',
		is_final_field: "false",
		trigger_recalc: "false",
		on_attr_id: e.on_attr_id
	    }
	    console.log(d)

	    WpApi.do_post('/hd-attr',d, (r:any) => {
		console.log(r)
		this.callback_after_vote(r)
	    })

	}else{
	    this.props.cb_parent(e,(r:any)=>{
		console.log("result after vote:")
		console.log(r)
		// reverse signal to Step
		//this.setState({ completed : (r.section_status === "ready")})
		this.callback_after_vote(r)
	    })
	}
    }
    is_expanded(){
	if(this.props.options.config !== undefined){
	    let conf = this.props.options.config
	    if( conf.level1 !== null){
		return conf.level1 === this.props.tup.key
	    }
	    return conf.blocktype === "simple"
	}
    }
    handle_info_click(){
	this.props.cb_parent({
	    cmd: 'open-info',
	    txt: this.props.tup.instructions
	},()=>{})
    }
    handle_comment_click(){
	console.log('comments-clicked')
	let lbl = 'Opmerkingen bij: ' +  this.props.tup.label
	this.props.cb_parent({
	    cmd: 'comment-vote',
	    acf_key_step: this.props.tup.key,
	    aspect:'huidig',
	    label: lbl
	},(r:any)=>{
	    console.log('callback after comment-vote')
	    let newtxt = r.txt
	    console.log(` add comment ${newtxt}`)
	    if(r.new_state){
		this.setState({
		    comments: r.new_state.map((x:any)=>x.value),
			is_loading:false
		})
	    }
	})
    }
    /*
     * Instruction / Info icon with popup potential
     */
    public render_instr(){
	let conf = this.props.options.config
	if(conf.filter !== undefined && conf.filter.level1 !== undefined){
	    return null
	}
	if(this.props.tup.instructions){
	    return (<div className="info-popup"><span className="cf-icon-btn"
		    data-balloon="Toon toelichting" data-balloon-pos="up"
		    onClick={this.handle_info_click}>
		    <i className="bb-icon-rl bb-icon-info"/>
		    </span></div>
		   )
	}
    }
    show_step(tup:any){
	if(this.props.view === "hdquotations"){
	    return tup.name === "zw-advies"
	}
	if(this.props.view === "buildingmeasures"){
	    return tup.name === "oordeel"
		|| tup.name === "uitvoering"
		|| tup.name === "zw-advies"
	}
	return true
    }
    componentDidMount(){
	this.setState({
	    //quotations: this.props.tup.quotations,
	    completed: (this.props.tup.value === 'ready')
	})

	/*
	   if('l1comments' in this.props.tup){
	   let txt_array = this.props.tup.l1comments.map((x:any)=>x.value)
	   this.setState({
comments: txt_array,
comment_count: this.props.tup.l1comment_count
})
}
*/
}
/*
   show_l1_comments(){
   if(!( 'l1comments' in this.props.tup)){
   return false
   }
   return ['actionlist'].includes(this.props.view)
   }
   render_l1_comments(){
   return this.show_l1_comments() ? (
   <div className="step remarks l1-remarks"  onClick={this.handle_comment_click} >
   <div className="text-with-btn">
   <div className="text">
   {this.state.comments.map((txt:string,j:number)=> {
   return (<div key={j} className="part" >{txt}</div>)
   })}
   </div>
   <span className="cf-count-btn"
   data-balloon="Keuze en details" data-balloon-pos="up"> {this.state.comment_count}</span>
   </div>
   </div>
   ) : null
   }
   */
	/*
	 *  part: (1 or 2)
	 *    part=1: show all rows except class 'bottom'
	 *    part=2: show only rows with class 'buttom'
	 */
render_l1_blocks(part:number){
    return this.props.tup.steps.map((tup:any, j:number) => {
	if(! this.show_step(tup)){
	    return null
	}

	if(tup.classes !== undefined && tup.classes.includes('bottom')){
	    if(part == 1){ return null }
	}else{
	    if(part == 2){return null}
	}




	if( tup.cftype === 'hda-choice-stap'){
	    // render level-1 steps
	    let l2 = { name: 'NOT_LEVEL2' }
	    if(tup.classes.includes('only-level2')){return null}
	    if(tup.classes.includes('verborgen')){return null}

	    return (
		<HdaStep
		l2cluster={l2}
		options={this.props.options}
		cb_parent={this.handle_cb}
		permission={this.props.permissions}
		key={j} tup={tup} post_id={this.props.post_id} />
	    )
	}else if( tup.cftype === 'sub-section'){
	    //return this.render_subsection(j,tup)
	    return (<SubSection key={j} tup={tup} cb_parent={this.handle_cb} options={this.props.options}
		    permission={this.props.tup.permission}

		    />)
	}else if( tup.cftype === 'hda-derived-field'){
	    return (<DerivedValueStep tup={tup} key={j}/>)
	}else if( tup.cftype === 'v1-inputs-with-comment'){
	    return (<V1Inputs
		    tup={tup} key={j}
		    parent_label=""
		    cb_parent={this.handle_cb}
		    /> )
	}else{
	    return null
	}
    })
}
render_l1_implement(){
    /*
    let classes = this.props.tup.classes
    if(!classes.includes('l1implement')){
	return null
    }
    let tup = this.props.tup
    let l1clust = {
	classes: tup.classes,
	on_attr:'',
	key: tup.key,
	name: tup.name,
	label: tup.label
    }
    //return (<div>l1-quotlist</div>)
    let opt = this.props.options.repeater_blocks
    opt.config = this.props.options.config
    let itup = {
	'huidig':{},
	'voorstel':{}
    }
    return (
	<ImplementationStep
	postid={Number(this.props.post_id)}
	level1={l1clust}
	level2={{name: 'NOT_LEVEL2'}}
	bouwdeel={{slug:'hele_woning'}}
	is_simple_block={false}
	readonly={false}
	tup={itup}
	options={opt}
	permission={this.props.permissions}
	cb_parent={this.handle_cb}
	/>
    )
   */
}
render_l1_quotations(){
    let classes = this.props.tup.classes
    if(!(classes.includes('l1quotations') || classes.includes('quot-archive-wtw'))){
	return null
    }
    let tup = this.props.tup
    let l1clust = {
	classes: tup.classes,
	key: tup.key,
	name: tup.name,
	label: tup.label
    }
    //return (<div>l1-quotlist</div>)
    let opt = this.props.options.repeater_blocks
    opt.config = this.props.options.config
    opt.config.view = this.props.view
    let archive_mode = tup.classes.includes('quot-archive-wtw')
    return (
	<QuotationsList 
	postid={Number(this.props.post_id)}
	l1cluster={l1clust}
	l2cluster={{name: 'NOT_LEVEL2'}}
	readonly={archive_mode}
	expanded={false}
	bouwdeel={null}
	quotations={tup.quotations}
	options={opt}
	permission={this.props.permissions}
	cb_parent={this.handle_cb}
	/>
    )
}
toggle_expand(e:any){
    let exp = new Expander_helper()
    exp.toggle_expand(e)
    this.props.cb_parent({ cmd:"toggle-expand"},()=>{})

}

public render(){
    if(this.props.tup.cftype === 'hda-section'){

	let label_txt = this.props.tup.label
	let with_identifier = false
	if(this.props.view === "actionlist"){
	    with_identifier = true
	}
	let classes = this.is_expanded() ? "highlight"  : "collapsed"
	let conf = this.props.options.config
	if(conf.filter !== undefined && conf.filter.level1 !== undefined){
	    // filtered list of only a single section / level1
	    with_identifier = false
	    // force expanded (unset class "collapsed")
	    classes = ""
	}
	if(conf.expandall){
	    classes = ""
	}
	if(with_identifier){
	    label_txt = this.props.tup.cft_identifier + '. ' + label_txt
	}



	return (
	    <div className={"section l1-cluster with-expand cluster "+classes}  acf-key={this.props.tup.key}>
	    <div className="header"  onClick={this.toggle_expand}>
	    <div className="pre-icon"></div>
	    <div className="label">
	    {label_txt}
	    {this.state.completed ? ' - AFGEROND' : null}
	    {this.render_instr()}
	    </div>
	    </div>
	    <div className="body">
	    <div className="steps">
	    {this.render_l1_blocks(1)}
	    </div>
	    {this.show_level2() ? (
		<Level2Clusters
		permissions={this.props.permissions}
		options={this.props.options}
		user={this.props.user}
		cb_parent={this.handle_cb}
		view={this.props.view}
		post_id={this.props.post_id} tup={this.props.tup}  />
	    ) : null }

	    <div className="steps">
	    {this.render_l1_quotations()}
	    {this.render_l1_blocks(2)}
	    </div>

	    </div>
	    </div>
	)
    }
}
show_level2(){
    return true
}
}
export default HdaSection;
