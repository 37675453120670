import React from 'react';
import WpApi from './wpapi'
import Loader from './Loader'
import Loco from './Loco'
import InfoModal from './modals/InfoModal'


/*
 *  states
 *   1 - aspirant-lid=ja    phone=ja 
 *   2 - aspirant-lid=ja    phone=nee
 *   3 - aspirant-lid=nee   phone=ja 
 *   4 - aspirant-lid=nee   phone=nee
 */

interface MyProps {
    xpcondition: any,
    acfkey:string,
    instr_field:string,
    force_reload:boolean,
    post_id:string
}

type MyState = {
    loading:boolean,
    value:string,
    message:string,
    show_save_btn:boolean,
    xp_input_value:string,
    xp_saved_value:string,
    context:any,
    info:string,
}
class HdSimpleBlock extends React.PureComponent<MyProps,MyState> {
    private info_popup =  React.createRef<InfoModal>();
    private xprofile_input = React.createRef<HTMLInputElement>();
    constructor(props: MyProps) {
        super(props)

	this.handle_info_click = this.handle_info_click.bind(this)
	this.handle_save_xpinput = this.handle_save_xpinput.bind(this)
	this.state = {
	    value:'',
	    xp_saved_value:'',
	    xp_input_value:'',
	    info:'',
	    context:null,
	    message:'',
	    show_save_btn:true,
	    loading:false
	}	
    }
    handle_info_click(){
	console.log('info')
	this.info_popup.current!.init(this.state.info)
    }
    handle_save_xpinput(e:any){
	this.setState({show_save_btn:false})
	let val = this.state.xp_input_value
	console.log(`saveing input ${val}`)
	let context = this.state.context
	let xpc = this.props.xpcondition
	let uid = context.user.user_id
	let fid = xpc.xprofile_field
	let path = `/wp-json/buddyboss/v1/xprofile/${fid}/data/${uid}`
	let data = {
	    value: [ val]
	}
	WpApi.do_patch(path,data,(r:any)=>{
	    console.log(r)
	    if(r.data && r.data.status > 200){
		this.setState({ message: r.message})
	    }else{
		if(this.state.context.hd_member_status.is_aspirantlid){
		    console.log('yep is aspirantlid')
		    if(r.value.raw){
			console.log(` got value: ${r.value.raw}`)
			if(this.props.force_reload){
			    this.reload_page()
			}
		    }
		}
		this.setState({
		    xp_saved_value: r.value.raw
		})
		
	    }
	});
    }
    componentDidMount(){
	this.setState({
	    xp_input_value: this.props.xpcondition.value,
	    xp_saved_value: this.props.xpcondition.value
	})

	let d = {
	    key: this.props.acfkey,
	    post_id: this.props.post_id,
	}
	WpApi.do_get('/acf-field',d, (r)=>{
	    console.log(r)
	    this.setState({

		context: r,
		value:r.value
	    })

	});
	if(this.props.instr_field !== null
	  && this.props.instr_field !== undefined){
	    let di = {
		x: 'test',
		key: this.props.instr_field
	    }
	    WpApi.do_get('/acf-field',di, (r)=>{
		this.setState({
		    info:r.field.instructions
		})
	    });
	}
    }
    handle_row_click(row:any){
	console.log('clicked row')
	console.log(row)
	this.setState({loading:true})

	let d = {
	    acf_key: this.props.acfkey,
	    level2: 'NOT_LEVEL2',
            post_id: this.props.post_id,
            votetype: "choice",
            aspect:  'huidig',
            is_final_field:false,
            value: row.name
	}
	console.log(d)
	WpApi.do_post('/hd-attr',d, (r) => {
	    console.log(r)
	    this.setState({
		value:r.winner
	    })
	    if(this.props.force_reload){
		this.reload_page()
	    }else{
		this.setState({
		    loading:false
		})
	    }
	})
    }
    reload_page(){
	window.location.reload()
    }
    // show link to Documents section in HDgroep
    render_link_documents(){
	return null  
	// TODO 
	let winner = this.state.value
	if(winner === "ontvangen"){
	    let url = this.state.context.group.url_bb + 'documents'
	    return (
		<div><a href={url}>
		link naar .. </a></div>
	    )

	}
	return null
    }
    render_rows(){
	let params = this.state.context
	if(params === null) { return null }
	let winner = this.state.value
	let rows = params.subfields.filter((el:any)=>{
	    let logic = el.conditional_logic
	    var show = false
	    if(logic){
		for(var rule of logic){
		    for(var r of rule){
			if(winner === r.value){
			    show = true
			}
		    }
		}
	    }
	    return show
	})
	
	return rows.map((row:any,i:number) =>{
	    return (
		<div className="row" key={i}>
		    {row.type === 'button'
		     ? this.render_button_row(row)
		     : (
			 
			 <div>
			    <div>{row.label}</div>
			    {this.render_link_documents()}
			 </div>
		     )
		    }
		    </div>
	    )
	})
    }
    render_button_row(row:any){
	return this.eval_xpcondition() ? (
	    <button onClick={(e)=>{this.handle_row_click(row)}}>
		{row.label}
	    </button>
	) : (
	    <button className="inactive-btn">
		{row.label}
	    </button>
	)
    }
    // return boolean
    eval_xpcondition(){
	return true

	if(this.state.context === null){return false}
	let xpc = this.props.xpcondition;

	if(xpc  === undefined){ return null }
	
	if(xpc === null){
	    return true
	}else{
	    if(xpc.eval === "not-empty"){
		return this.state.xp_saved_value !== ""
	    }
	}
	return false
    }

    tr(value:string) : string {
	let context = this.state.context
	if(context == null){
	    return Loco.tr(value)
	}
	let nl = context.loco

	if( value in nl) {
	    return nl[value]
	}
	return Loco.tr(value)
    }
    render_xprofile_field(){
	return null
	let phone_text =  this.eval_xpcondition() ?
	    "Je kunt gebeld worden voor een afspraak. Klopt dit telefoonnummer nog? Zo niet, dan kun je het hier wijzigen"
	    : this.tr('please_enter_your_phone')
	    return (
		<div className="rows">
		   <div className="row">{phone_text}</div>
    		    <div className="row xprofile-input">
		
			<input ref={this.xprofile_input}
		    	value={this.state.xp_input_value}
			onChange={(e) => this.setState({ xp_input_value: e.target.value })}
			/>
			{this.state.show_save_btn ? (<button onClick={this.handle_save_xpinput}>Sla op</button>) : null}
			<div className="message">{this.state.message} </div>
		    </div>
	      	</div>	
	    )
    }
    render(){
	
	let params = this.state.context
	let pre_label = this.tr('your_digidome')

	
	
	return params === null ? ( <Loader /> ) : (
	    <div className="hd-simple-block">
		{this.state.loading ? <Loader /> : null}
		    <div className="hd-label">
			{pre_label}
			<a target="_blank" href={params.group.url}>{params.group.title}</a>
		    </div>

		<div className="rows">
		    {this.render_rows()}
		</div>
		{this.render_xprofile_field()}
		
		<div className="info">
		    {this.state.info !== '' ? this.render_info() : null}
		</div>
		<InfoModal ref={this.info_popup} />
	    </div>
	)
    }
    render_info(){
	if(this.state.info === ''){
	    return null
	}
	let txt = 'Klik hier voor meer info'
	return (<span className="cf-icon-btn info"
		data-balloon={txt} data-balloon-pos="up"
		onClick={this.handle_info_click}>
	    {txt} <i className="bb-icon-rl bb-icon-info"/>
		</span>
	    )

    }
}
export default HdSimpleBlock
