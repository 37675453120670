import * as React from 'react';
import Expander_helper from './Expander_helper'
import WpApi from './wpapi'
import Loco from './Loco'
import FilteredChoices from './filtered_choices'
import Level2BuildingPart from './Level2BuildingPart'

interface MyProps {
    post_id:string,
    compactview:boolean,
    permissions:any,
    view:string,
    options?:any,
    cb_parent: (e: any, cb: (r: any) => void) => void,
    ptup:any,
    tup:any
}
type MyState = {
    show_select:boolean,
    rows:Array<any>,
    is_loading:boolean,

}
class Level2Cluster extends React.PureComponent<MyProps,MyState> {
    private fetchPromise ?: Promise<void>
    constructor(props: MyProps) {
        super(props)
	this.handle_select_change = this.handle_select_change.bind(this)
	this.load_building_parts = this.load_building_parts.bind(this)
	this.render_select = this.render_select.bind(this)
	
	this.state = {
	    rows:[],
	    show_select:false,	    
	    is_loading:false,

	}
	
    }

    componentDidMount(){
	if(this.props.options === null){
	    return
	}
	if(this.props.compactview){
	    this.load_building_parts(null)
	}
	if(this.is_expanded()){
	    this.load_building_parts(null)

	}
    }
    is_expanded(){
	let name = this.props.tup.name
	if(this.props.options.config !== null){
	    let conf = this.props.options.config
	    if(conf.level2 !== undefined){
		return conf.level2 === this.props.tup.name
	    }
	}
	return false
    }


    public handle_select_change(e:any){
	console.log('handle-sel-change')
	console.log(e)
	if(e.target.value === ""){
	    return
	}
	
	//this.setState({show_select:false})
	//let rows = this.state.rows
	let tup = {
	    slug: e.target.value,
	    tup: {
		comments:[],
		quotations:[],
		implementations:[],
		advises:{}
	    },
	    
	}
	console.log(tup)
	this.setState(prevState => ({
	    rows: [...prevState.rows, tup]
	}))

    }
    get_bd_remaining(){
	let buttons = this.props.options.repeater_blocks.buildingparts.buttons


	let btns = buttons.filter((el:any,i:number)=>{
	    let cid = el['cft-identifier']
	    let cls = "bp-" + cid
	    return this.props.ptup.classes.includes(cls)
	})

	console.log(` filter by L1 ${this.props.ptup.name}`)
	console.log(btns)

	let present_slugs = this.state.rows.map((x:any) => x.slug)
	return btns.filter((x:any)=>{
	    return (!present_slugs.includes(x.name));
	});
    }
    public render_select(){
	let bd_remaining = this.get_bd_remaining()
	if(bd_remaining.length === 0) {
	    return null
	}
	return (
	    <div className="label-with-select add-bouwdeel"> 
	    <div className="label">
	    Optioneel: Voeg (nog) een bouwdeel toe i.v.m. <i>{this.props.tup.label} </i>: </div>
		<select style={{height:'unset'}} onChange={this.handle_select_change}>
		<option value="">-kies-</option>
		{bd_remaining.map((el:any,i:number)=><option key={i} value={el.name}>{el.label}</option>)}
 	    </select>
		</div>
	)
    }
    
    load_building_parts(e:any){
	console.log('load buildingparts')
	if(e !== null){
	    let exp = new Expander_helper()
	    exp.toggle_expand(e)
	}
	
	if (this.fetchPromise) {
	    console.log(' already mounted previously')
	    return
	}

	this.setState({is_loading:true})
	let path = '/buildingparts'
	let fieldkeys = this.props.tup.fields.map((x:any) => x.key )
	let d = {
	    post_id : this.props.post_id,
	    level2: this.props.tup.name,
	    level1: this.props.ptup.key,
	    fields: fieldkeys
	}


	this.fetchPromise = WpApi.do_get(path,d, (ret) => {
	    let retlist : {[bd:string]:Array<any>} = ret.ret
	    console.log(retlist)

	    this.setState({is_loading:false, show_select:true})
	    //Object.entries(obj) as ObjectEntries<OBJ_T>;

	    Object.entries(retlist).forEach(([bd, tup]: [string, any])=>{

		var advises : Array<any> = []
		let bdtup = {
		    slug: bd,
		    tup:tup
		}
		console.log(bdtup)
		this.setState(prevState => ({
		    rows: [...prevState.rows, bdtup]
		}))

	    })


	    /*
	    // currently present BD-slugs:

	    var postfix = ""
	    if(this.props.tup.section_name.startsWith('isolatie')){
		let idx = 'isolatie_'.length
		postfix = this.props.tup.section_name.substr(idx)
		console.log(` postfix: ${postfix}`)
	    }

	    let choices = this.props.options.bouwdelen.choices

	    for(var [val,lab] of Object.entries(choices)){
		let tup = {
		    val: val,
		    label: lab,
		}
		if(present_slugs.includes(val)){
		    continue
		}else{
		    if(postfix !== ""){
			if(val.startsWith(postfix)){
			    console.log('matches postfix')
			}else{
			    continue
			}
		    }
		    this.setState(prevState => ({
			bd_remaining: [...prevState.bd_remaining, tup]
		    }))
		}
	    }
	    */
	})

    }
    
    with_bp(){
	if(this.props.tup.classes !== undefined){
	    if(this.props.tup.classes.includes('no-buildingparts')){
		return false
	    }
	}

	return this.props.ptup.classes.includes('buildingparts')

    }
    render_buildingparts(){
	let with_bp =  this.with_bp()
	let l1c = {
	    name: this.props.ptup.name,
	    label: this.props.ptup.label,
	    classes: this.props.ptup.classes,
	    key: this.props.ptup.key
	}
	return (
	    <div className="body bouwdelen">
		<div className="rows">
		{this.render_calc_result()}
		{this.state.rows.map((row:any,i:number)=>
		    <Level2BuildingPart
			key={i}
			compactview={!with_bp}
			view={this.props.view}
			tup={row}
			level1cluster={l1c}
			stup={this.props.tup}
			permissions={this.props.permissions}
			cb_parent={this.props.cb_parent}
			post_id={this.props.post_id}
			options={this.props.options}
		    />
		)}
		</div>
		{with_bp && this.state.show_select  ? this.render_select() : null}
	    </div>
	)
    }
    render_calc_result(){
	let tup = this.props.tup
	if(tup.calc_result === undefined){return null}
	let row = tup.calc_result
	let link = row.ref_link === undefined ? null :  (
	    <a target="_blank" href={row.ref_link.url}> (klik hier voor bron) </a>
	)

	return (
	    <div className="calc-result">
		<div className="label">{row.field.label}, </div>
		<div className="value">
	     	    berekende waarde:  {row.result} {row.field.append}
		</div>
		<div className="bron">
		  {link}
		</div>
	    </div>
	)
    }
    render(){
	let row = this.props.tup
	if(this.props.compactview){
	    return this.render_buildingparts()
	}
	let name = this.props.tup.name
	let collapsed = this.is_expanded() ? "" : "collapsed"
	return (
	    <div className={"l2-cluster cluster with-expand "+collapsed} l2-name={name} >
		<div className="header"  onClick={this.load_building_parts}>
		    <div className="pre-icon"></div>
		    <div className="label">{row.label}</div>
		</div>
		{this.render_buildingparts()}
	    </div>
	)
    }
}
export default Level2Cluster
