import $ from 'jquery'

class GridHeader {
    click_col_header(e:any){
	if(['INPUT','SELECT','OPTION'].includes(e.target.nodeName)){
	    return
	}
	let trg = e.target.closest('.hdr-label')
	let name = trg.classList[0]
	console.log(` col-header clicked name: ${name}`)
	let content_type = 'str'
	if(trg.classList.contains('int')){
	    content_type = 'int'
	}

	let cur = 'none'
	
	if(trg.classList.contains('sorting-d')){
	    cur = 'sorting-d'
	}else{
	    if(trg.classList.contains('sorting-a')){
		cur = 'sorting-a'
	    }
	}
	$('.listgrid > .header >div').removeClass('sorting-a');
	$('.listgrid > .header >div').removeClass('sorting-d');

	console.log(`current mode: ${cur}`)
	if(cur === 'sorting-a' || cur === 'none'){
	    console.log('set sorting-d')
	    trg.classList.add('sorting-d')
	    this.sort_rows(name,true,content_type)
	}else{
	    console.log('set sorting-a')
	    trg.classList.add('sorting-a')
	    this.sort_rows(name,false,content_type)
	}
    }
    sort_rows(name:string, rev:boolean,content_type:string){
	console.log(`sort_rows ${name}`)

	let body = '.listgrid > .body'
	//@ts-ignore
	$(body + ' > .grid-row').sort((a:HTMLElement,b:HTMLElement)=>{
	    let el_a = $(a).find(`.row > .${name}`)
	    let el_b = $(b).find(`.row > .${name}`)
	    let ta = el_a.text().toLowerCase();
	    let tb = el_b.text().toLowerCase();
	    if(name === "date"){
		let tit_a = el_a.attr('title')
		let tit_b = el_b.attr('title')
		if(tit_a !== undefined){
		    ta = tit_a
		}
		if(tit_b !== undefined){
		    tb = tit_b
		}
	    }
	    console.log(` ta ${ta} tb ${tb}`)
    	    let ret = (ta > tb ? 1 : (ta < tb ? -1 : 0));
	    if(content_type === 'int' || name === 'date'){
		let ia = Number(ta)
		let ib = Number(tb)
		ret = (ia > ib ? 1 : (ia < ib ? -1 : 0));
	    }
	    if(rev){
		return ret * -1;
	    }
	    return ret
	}).appendTo(body)
    }

    change_filter_input(_e:any){
	$('.grid-row > .row').removeClass('verberg')
	$('.grid-row').addClass('collapsed')

	let filters : {[k:string]: string } = {}
	document.querySelectorAll('.listgrid > .header input').forEach((e)=>{
	    let k = $(e).attr('name')
	    if(k !== undefined){
		let v = String( $(e).val()).toLowerCase()
		if(v){
		    filters[k] = v
		}
	    }
	});
	document.querySelectorAll('.listgrid > .header select').forEach((e)=>{
	    let k = $(e).attr('name')
	    console.log(`select  ${k}`)
	    if(k !== undefined){
		let v = String( $(e).val()).toLowerCase()
		if(v){
		    filters[k] = v
		}
	    }
	})
	console.log(filters)
	document.querySelectorAll('.grid-row > .row').forEach((row) => {
	    Object.entries(filters).map(([name,val]:[string,string]) =>{
		let child = row.querySelector(`.${name}`)
		if(child){
		    let text = ''
		    if(child.textContent !== null){
			text = child.textContent.trim()
		    }
		    console.log(` - ${name} [row-text ${text.length} ${text} ] filter by ${val}`)
		    if(val === 'leeg'){
			if(text.length > 0){
			    row.classList.add('verberg')
			}
		    }else if(val === 'niet-leeg'){
			if(text.length === 0){
			    row.classList.add('verberg')
			}
		    }else{
			if(!text.toLowerCase().includes(val)){
			    row.classList.add('verberg')
			}
		    }
		}else{
		    console.log(` no child found for name ${name}`)
		}
	    })
	});
	return document.querySelectorAll('.row:not(.verberg)').length

    }
    
}

export default GridHeader
