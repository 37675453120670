class Date_formatting {
    get_formatted_dt(d:Date):string{
	let date = this.get_formatted_d(d)
	let hh = d.getHours()
	let min = String(d.getMinutes()).padStart(2,'0')
	return `${date} ${hh}:${min}`
    }
    get_formatted_d(d:Date):string{
	let yyyy = d.getFullYear()
	let mm = String(d.getMonth() + 1).padStart(2,'0')
	let dd = String(d.getDate()).padStart(2,'0')
	return `${dd}-${mm}-${yyyy}`
    }
    get_formatted_date(i:number):string{
	if(isNaN(i)){return ""}
	let d = new Date(i)
	return this.get_formatted_d(d)
    }
    get_formatted_date_time(i:number):string{
	if(isNaN(i)){return ""}
	let d = new Date(i)
	return this.get_formatted_dt(d)
    }
}
export default Date_formatting
