import * as React from 'react'
import Loco from './Loco'
import Expander_helper from './Expander_helper'
import PotMeasure from './PotMeasure'

import ChosenMeasure from './ChosenMeasure'

interface MyProps {
    postid:number,
    options:any,
    expanded:boolean,
    permission:any,
    mode:string,
    l1cluster:any,
    l2cluster:any,
    measures: any,
    implementations?:Array<any>,
    quotations?:Array<any>,
    bouwdeel:any,
    cb_parent:   (e: any, cb: (r: any) => void) => void
}
type MyState = {
    is_loading:boolean,
    chosen: Array<number>
    expand_chosen_measure:number
}

class PotMeasuresList extends React.PureComponent<MyProps,MyState> {

    private choice_dropdown =  React.createRef<HTMLSelectElement>()

    constructor(props: MyProps) {
        super(props)
    	this.handle_comment_click = this.handle_comment_click.bind(this)
	this.handle_data_click = this.handle_data_click.bind(this)
	this.on_change_dropdown	= this.on_change_dropdown.bind(this)	

	this.state = {
	    is_loading:false,
	    expand_chosen_measure:-1,
	    chosen:[]
	}
	
    }

    componentDidMount(){
	if(this.props.measures !== undefined){
    	    // split quotations per linked_attr, which is the measure_id
	    let chosen: Array<number> = []
	    let entries = Object.entries(this.props.measures)

	    entries.forEach(( [aid,mtup]:[string,any]) => {
		console.log(` aid of props.measures : ${aid}`)
		var aidn = Number(aid)
		if(mtup !== undefined){
		    console.log(mtup)
		    if(mtup.implementation !== undefined){
			if(mtup.implementation.huidig.winner !== null
			|| mtup.implementation.voorstel.winner !== null
			    ){
			    if(!chosen.includes(aidn)){
				console.log(`push ${aidn} to chosen`)
				chosen.push(aidn)
			    }
			}
		    }
		}
		if(this.props.quotations !== undefined){
		    for(var q of this.props.quotations){
			if(Number(q.linked_attr) === aidn){
			    if(!chosen.includes(aidn)){
				chosen.push(Number(aidn))
			    }
			}
		    }
		}
	    })
	    this.setState({chosen:chosen})
	}
    }
    get_label(){
	let opwek = this.props.l1cluster.name === "opwek"
	let location = opwek ? "en/of lokaties" : ""
	return `Potentiële maatregelen (longlist) ${location} voor: ${this.props.l2cluster.label}`
    }
    handle_data_click(){
	console.log('adding advise')
	let compact = this.props.l1cluster.name === "opwek"
	let bd = this.props.bouwdeel.slug
	let l2 = this.props.l2cluster.name
	this.props.cb_parent({
	    cmd: 'advise-vote',
	    with_history:true,
	    history_popup: {
		post_id: this.props.postid,
		acf_key: this.props.l1cluster.key,
		choices: [],
		bouwdeel:bd,
		aspect: 'voorstel',
		label: 'Historie',
		level2: l2
	    },
	    acf_key: this.props.l1cluster.key,
	    compactview: compact,
	    label: this.get_label(),
	    level2: l2,
	    bouwdeel: bd,
	    aspect: 'voorstel',
	    top3: Object.keys(this.props.measures)
	}, (r:any)=>{
	    // callback is received twice:
	    if(r.hook === 'before_api_call'){
		// 1. the popup is closed but API-call is still running
		this.setState({is_loading:true})
	    }else{
		// 2. the API-call is finished, with result
		console.log('handle-advise-click ; after voting')
		if(r.bd_advise){
		    //this.setState({measures:r.bd_advise, is_loading:false})
		}
	    }
	})
    }
    handle_comment_click(attr_id:string, cb:(r:any)=>void){
	console.log(`advise-comment-click ${attr_id}`)
	let adv :any = this.props.measures[attr_id]
	let bd = this.props.bouwdeel.slug
	let l2 = this.props.l2cluster.name

	this.props.cb_parent({
	    cmd: 'advise-comment-vote',
	    with_history:true,
	    history_popup: {
		post_id: this.props.postid,
		acf_key: this.props.l1cluster.key,
		attr_id:attr_id,
		votetype:'comment',
		choices: [],
		bouwdeel:bd,
		aspect: 'voorstel',
		label: 'Historie',
		level2: l2
	    },
	    attr_id: attr_id,
	    label: this.get_label(),
	    top3: adv.comments
	}, (r:any)=>{
	    // callback is received twice:
	    if(r.hook === 'before_api_call'){
		// 1. the popup is closed but API-call is still running
		this.setState({is_loading:true})
	    }else if(r.votetype === "add_comment"){
		console.log('handle add-comment')
		this.setState({is_loading:false})
		cb(r)
	    }else{
		console.log('finally handle callback')
		this.setState({is_loading:false})
		cb(r)
	    }
	})
    }
    render_advises(){
	let compact = this.props.l1cluster.name === "opwek"
	let list = Object.entries(this.props.measures).map(([attr_id, tup]: [string, any]) => {
	    if(tup === undefined){return null}
	    return (
	    <PotMeasure
		attr_id={attr_id}
		value={tup.value}
		autonum={tup.autonum}
		compactview={compact}
		comments={tup.comments}
		comment_count={tup.comment_count}
		handle_comment_click={this.handle_comment_click}
		handle_data_click={this.handle_data_click}
		key={attr_id} />
	)})
	let opwek = this.props.l1cluster.name === "opwek"
	let location = opwek ? "en/of lokatie" : ""
	return (
	    <div className="list-with-button">
    		{list}
		<span className="cf-button" onClick={()=>this.handle_data_click()}>Voeg een Potentiële maatregel {location} toe</span>
	    </div>
	)
    }
    render_chosen_measure(attr_id:number){
	let tup = this.props.measures[attr_id]
	if(this.props.quotations === undefined){
	    return null
	}
    	let qlist = this.props.quotations
	let is_expanded = attr_id === this.state.expand_chosen_measure
	return (
	    <ChosenMeasure 
		postid={this.props.postid}
		expanded={is_expanded}
		options={this.props.options}
		permission={this.props.permission}
		l1cluster={this.props.l1cluster}
		l2cluster={this.props.l2cluster}
		quotations={qlist}
		bouwdeel={this.props.bouwdeel}
		tup={tup}
		cb_parent={this.props.cb_parent}
		key={attr_id} 
	    />
	)
    }
    render_chosen_measures(){
	let list = this.state.chosen.map((attr_id) => this.render_chosen_measure(attr_id))
	return (<div className="chosen-measures">{list}</div>)
    }
    /*
     * a measure was chosen. This will result in rendering of ChosenMeasure
     */
    on_change_dropdown(e:any){
	let attr_id = e.target.value
	this.setState(prev => ({
	    expand_chosen_measure: Number(attr_id),
	    chosen : [...prev.chosen, Number(attr_id)]
	}))
	console.log(` chosen advise ${attr_id}`)
	// reset dropdown to 'empty'
	let elm = this.choice_dropdown.current
	if(elm !== null){
	    elm.value = "empty"
	}
    }
    /*
     * render potential measure which have a quotation or implementation attribution
     */
    render_choice_dropdown(){

	let options = Object.entries(this.props.measures).map(([attr_id,tup]:[string,any]) => {
	    if(tup === undefined){return null}
	    if(this.state.chosen.includes(Number(attr_id))) { return null}
	    let txt = tup.value.text
	    let max = 60;
	    if(txt.length > max){
		txt = txt.substring(0,max) + '..'
	    }
	    return (
    		<option key={attr_id} value={attr_id}>{txt}</option>
    	    )
	})
	return (
	    <div>
		{options.length === 0 ? (
		    <div>
			Er zijn nog geen Potentiële maatregelen. Die kunnen hierboven worden toegevoegd, zie
			Potentiële maatregelen (longlist).
		    </div> 
		) : (
		<div className="label-with-select">
		    <span className="label">
			Selecteer (nog) een Potentiële maatregel waar je Offertes voor wilt aanvragen:
		    </span>
		    <select ref={this.choice_dropdown} onChange={this.on_change_dropdown}>
			<option value="empty">-kies-</option>
			{options}
		    </select>
		</div>
		)}
	    </div>
	)
    }
    render_choice(){
	if(['ontvangen','online_ontvangen'].includes(this.props.permission.hd_member_status.status)){
	    return (
		<div className="choice-mode">
		    {this.render_chosen_measures()}
		    {this.render_choice_dropdown()}
		</div>
	    )
	}else{
	    return (
		<div>Offertes kunnen pas worden aangevraagd nadat het Zonnewarmte-advies is ontvangen</div>
	    )
	}
    }

    render(){
	let exp = new Expander_helper()
	let opwek = this.props.l1cluster.name === "opwek"
	let location = opwek ? "en/of lokaties" : ""
	let title  = `Potentiële maatregelen (longlist) ${location} voor`
	if(this.props.mode === "choice"){
	    title = "Offertes en uitvoering - per Geselecteerde maatregel voor"
	}
	let bdlabel = this.props.bouwdeel.label

	let label =  (
	    <div className="label">
	    <span>
	    {title}: <i> {this.props.l2cluster.label} (voor bouwdeel {bdlabel})</i>
	    </span>
	    </div>
	)
	let collapsed = this.props.expanded ? "" : "collapsed"

	return (
	    <div className={"quotations with-expand "+collapsed}>
	     	<div className="header" onClick={exp.toggle_expand}>
		    <div className="pre-icon"></div>
		    {label}
		</div>
		<div className="body">
		    <div className="pot-measures advises">
		    {this.props.mode === "choice" ? this.render_choice() : this.render_advises()}
		    </div>
		</div>
	    </div>
	)
    }
}
export default PotMeasuresList

