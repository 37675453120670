import * as React from 'react'
import $ from 'jquery'
import WpApi from './wpapi'
import Loader from './Loader'
import QuotationsAndCustomersRow from './QuotationsAndCustomersRow'
import VoteGridModal from './modals/VoteGridModal'
import HistoryModal from './modals/HistoryModal'
import GridHeader from './GridHeader'

interface MyProps {
    bbgroup:any,
    query:any
}
type MyState = {
    loading:boolean,
    query:any,
    options: any,
    level2_options: {[k:string]:string},
    permissions:any,
    level2_filter:string,
    rows:Array<any>
}
interface cb_after_vote_event {
    (r:any): void;
}

class QuotationsAndCustomers extends React.PureComponent<MyProps,MyState> {
    private fetchPromise ?: Promise<void>    
    private vote_popup =  React.createRef<VoteGridModal>()
    private history_popup =  React.createRef<HistoryModal>()
    private callback_after_vote_event :cb_after_vote_event = (x)=>{ console.log("dummy")}

    constructor(props: MyProps) {
        super(props)
	this.after_vote_event = this.after_vote_event.bind(this)	
	this.open_popup = this.open_popup.bind(this)
	this.click_col_header = this.click_col_header.bind(this)
	this.change_level2_filter = this.change_level2_filter.bind(this)
	
	this.state = {
	    rows: [],
	    options:{},
	    level2_filter:'',
	    level2_options:{},
	    query:{},
	    permissions:{},
	    loading:true
	}
    }

    
    init(){
	this.setState({loading:true})

	if (this.fetchPromise) {
	    console.log(' already mounted previously')
	    return;
	}


	let d = this.props.query


	this.fetchPromise = WpApi.do_get('/hd-quotations', d,(r) => {
	    let perm = r.permissions
	    perm.bbgroup = this.props.bbgroup
	    let level2_options : {[k:string]:string} = {
		'' : '-kies-'
	    }

	    for(var row of r.rows){
		let l2 = row.cluster.level2
		level2_options[l2.label] = l2.label
	    }

	    this.setState({
		rows  : r.rows,
		query : r.query,
		level2_options : level2_options,
		options : r.options,
		permissions: perm,
		loading:false
	    })
	})
	    

    }    
    componentDidMount(){
	console.log('did mount')
	this.init()
    }
    reset_filters(){
	console.log('reset-filters')
	$('.quotation .row').removeClass('verberg')
	$('.quotation.header input').val('');
	$('.quotation.header .hdr-label').removeClass('sorting-d')
	$('.quotation.header .hdr-label').removeClass('sorting-a')
	
	
    }
    
    /*
     * render header with table-columns
     */
    render_header(){
	let q = this.state.query
	if(q.mode === undefined){ return null}
	let single_supplier_mode = q.mode === "suppliers" && q.list.length === 1
	let skip_level2 = false
	if(q.list !== undefined && q.list.includes("opwek")){
	    skip_level2 = true
	}
	let titles = {
	    adres: 'Adres',
	    level2: 'Onderdeel',
	    status1: 'Offerte-status volgens bewoner',
	    status2: 'Offerte-status volgens leverancier',
	    supplier: 'Leverancier',
	    date : 'Laatste wijziging',
	}

	return (
	    <div className={"header"}>
		<div className="pre-icon-h"></div>
		{Object.entries(titles).map(([name,label]:[string,string],i:number) => {
		    if(skip_level2 && name === 'level2'){ return null}
		    if(single_supplier_mode){
			if(name==='supplier'){return null}
		    }
		    let filter = (
			<input name={name} type={"text"} onChange={this.change_filter_input} />
		    )
		    if(name === 'level2'){
			filter = (
			    <select name={name} value={this.state.level2_filter} onChange={this.change_level2_filter}>
			      {Object.entries(this.state.level2_options).map(([ok,lab]:[string,string]) => (
				  <option key={ok} value={ok}>{lab}</option>
			      ))}
			    </select>
			)
		    }
			return (
			    <div key={i} className={name + " hdr-label"} onClick={this.click_col_header}>
				<div className="label"><div className="txt">{label}</div></div>
				{filter}
			    </div>
			)
		})}

	    </div>
	)
    }
    click_col_header(e:any){
	let gh = new GridHeader()
	gh.click_col_header(e)
    }
    change_level2_filter(e:any){
	console.log('change level2')
	this.setState({level2_filter: e.target.value})
	if(e.target.value === '-kies-'){return}
	let gh = new GridHeader()
	gh.change_filter_input(e)

    }
    change_filter_input(e:any){
	let gh = new GridHeader()
	gh.change_filter_input(e)
    }
  
    public open_popup(e:any, cb:(r:any)=>void){
	this.callback_after_vote_event = cb
	console.log(`open-popup cmd: ${e.cmd}`)
	console.log(e)
	let votetypes = [
	    'quotations-comment-vote',
	    'quotation-status-vote',
	    'choice-vote',
	    'comment-vote'
	]

	let history = [
	    'quotation-history',
	    'open-history'
	]
	if(votetypes.includes(e.cmd)){
	    this.vote_popup.current!.init(e)
	}else if(history.includes(e.cmd)){
	    this.history_popup.current!.init(e)
	}else{
	    console.log(`ERROR unknown cmd ${e.cmd}`)
	    return
	}
    }
    public after_vote_event(v:any){
	console.log(`got vote-event kind ${v.kind}`)
	console.log(v)
	let d :{[name:string]:string}= {
	    post_id: v.post_id,
	    votetype: v.kind,
	}
	if(v.votetype !== undefined){
	    d.votetype = v.votetype
	}
	if(v.acf_key !== undefined){
	    d.acf_key = v.acf_key
	}
	if(v.kind === "add_comment"){

	    d.aspect = v.aspect
	    d.value = v.txt
	    d.level2 = v.level2
	    if(v.on_attr_id !== undefined){
		d.on_attr_id = v.on_attr_id
	    }
	}
	else if(v.kind === "update_comment"){
	    d.attr_id =  v.attr.attr_id
	    d.value = v.comment
	    if(v.on_attr_id !== undefined){
		d.on_attr_id = v.on_attr_id
	    }
	}
	else if(v.kind === 'remove_vote'){
	    d.attr_id = v.attr_id
	}
	else if(v.kind === 'chosen_option'){
	    d.value = v.value
	    d.is_final_field = v.is_final_field
	    d.trigger_recalc = v.trigger_recalc
	    d.aspect = v.aspect
	    if(d.acf_key === ""){
		d.acf_key = v.acf_key_select
	    }
	    if(v.on_attr_id !== undefined){
		d.on_attr_id = v.on_attr_id
	    }
	    d.level2 = v.level2
	    
	}
	else{
	    console.log('error unknown kind')
	    return
	}
	console.log(d)
	WpApi.do_post('/hd-attr',d, (r) => {
	    this.callback_after_vote_event(r)
	})
	
    }
    render_rows(){
	let q = this.state.query
	if(q.mode === undefined){return null}
	let opt = this.state.options
	opt.config = {
	    view: "quotations-listgrid",
    	    single_supplier_mode : q.mode === "suppliers" && q.list.length === 1
	}
	opt.query = this.state.query

	return this.state.rows.map((row, i) => {
	    return (
		<QuotationsAndCustomersRow
		key={i} row={row}
	    	cb_parent={this.open_popup}
		options={opt}
		permissions={this.state.permissions}
		/>
	    )
	})
    }
    render(){
	let q = this.props.query

	return (
	    <div className={"hd-actions-react listgrid quotations-listing "+q.mode}>
		<span className="cf-button reset-filters" onClick={this.reset_filters}>Reset filters</span>

		{this.render_header()}

		{this.state.loading ? <Loader /> : null}		
		<div className="body">
		    {this.render_rows()}
		</div>

		<VoteGridModal ref={this.vote_popup}
	    cb_parent={this.after_vote_event} post_id="undef" />
		<HistoryModal ref={this.history_popup}  post_id="undef" />
	    </div>
	)
    }
}
export default QuotationsAndCustomers

