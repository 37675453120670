import * as React from 'react';
import Loader from './Loader'

import {advise_type, render_advise} from './Advise'

interface MyProps {
    attr_id:string,
    autonum:string,
    comments: Array<string>,
    compactview:boolean,
    comment_count:number,
    handle_comment_click: (a:string, cb: (r: any) => void) => void,
    handle_data_click: (e: any) => void,
    value:any
}
type MyState = {
    is_loading:boolean,
    comment_count:number,
    comments: Array<string>
}
class PotMeasure extends React.PureComponent<MyProps,MyState> {
    constructor(props: MyProps) {
        super(props)
	this.handle_comment_click = this.handle_comment_click.bind(this)
	this.handle_data_click = this.handle_data_click.bind(this)
	this.state = {
	    is_loading:false,
	    comment_count:0,
	    comments: []
	}
    }
    handle_comment_click(e:any){
	console.log('comments-clicked')
	//cmd: 'advise-comment-vote',
	this.props.handle_comment_click(this.props.attr_id,(r:any)=>{
	    console.log('callback after comment-vote')
	    console.log(r)
	    if(r.new_state){
		console.log('got new-state')
		this.setState({
		    comments: r.new_state.map((x:any)=>x.value),
		    comment_count: r.new_state.length,
		    is_loading:false
		})
	    }
	})
    }
    
    componentDidMount(){
	let txt_array = this.props.comments.map((x:any)=>x.value)
	
	this.setState({
	    comments: txt_array,
	    comment_count: this.props.comment_count
	})
    }

    handle_data_click(e:any){
	console.log('advise')
	this.props.handle_data_click({
	    cmd: 'advise-vote',
	})
    }
    render_comments(has_edit_perm:boolean){
	let leeg_txt = "(zet hier je opmerkingen)"
	if(this.state.comments.length==0 && has_edit_perm){
	    return (<div className="text empty"><i>{leeg_txt}</i></div>)
	}
	return (
	    <div className="text">
		{this.state.comments.map((txt:string,j:number)=> {
		    let k = `t-${j}`
		    return (<div key={k} className="part" >{txt}</div>)
		})}
	    </div>
	)
    }

    render(){
	let adv_txt = this.props.value === undefined ? (<div>undefined</div>) : render_advise(this.props.value,this.props.compactview)
	let num = this.props.autonum
	return (
	    <div className="bd-advise one-column">
		{this.state.is_loading ? <Loader/> : null}
		<div className="advise winner" onClick={this.handle_data_click}> 
	    	<div className="label"> Potentiële maatregel #{num}:&nbsp; {adv_txt}</div>
	    	    <span data-balloon="Details / keuze"
			  data-balloon-pos="up" className="cf-icon-btn"><i className="bb-icon-f bb-icon-ellipsis-h"/></span>
		</div>
		<div className="remarks"  onClick={this.handle_comment_click} >
		    <div className="text-with-btn">
			{this.render_comments(true)}
			<span className="cf-count-btn"
			      data-balloon="Keuze en details" data-balloon-pos="up"> {this.state.comment_count}</span>

		    </div>
		</div>
		
	    </div>
	)
    }
}

export default  PotMeasure
