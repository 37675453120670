import * as React from 'react'
import WpApi from './wpapi'
import Loco from './Loco'
import Loader from './Loader'
import Conditional_dropdown from './Conditional_dropdown'



interface MyProps {
    tup:any,
    readonly:boolean,
    aspect:string,
    acfkey:string,
    label:string,
    permission:any,
    postid:number,
    on_attr_id:number,
    options:any,
    cb_parent:   (e: any, cb: (r: any) => void) => void
}
type MyState = {
    comments: Array<string>,
    comment_count: number,
    value_count:number,
    status_lab:string,
    status:string,
    status_count:number,
    is_loading:boolean
}
class QuotationStatus extends React.PureComponent<MyProps,MyState> {
    private labels: { [string: string]: string } 	= {
	'huidig' :  Loco.tr('header_quotation_status'),
	'voorstel' : Loco.tr('header_quotation_advise'),
    }
			   
    constructor(props: MyProps) {
        super(props)
        this.handle_comment_click = this.handle_comment_click.bind(this)
	this.handle_value_click = this.handle_value_click.bind(this)
	this.handle_dropdown_change = this.handle_dropdown_change.bind(this)
	this.handle_click_history = this.handle_click_history.bind(this)
	this.state = {
	    comments:[],
	    comment_count:0,
	    value_count:0,
	    status:'',
	    status_lab:'',
	    status_count:0,
	    is_loading:false
	}	
    }
    componentDidMount(){
	let atup = this.props.tup
	if('comments' in atup){
	    let txt_array = this.props.tup.comments.map((x:any)=>x.value)
	    this.setState({
		comments: txt_array,
		comment_count: this.props.tup.comment_count
	    })
	}
	let status = atup.status
	if(status ){
	    let stat_lab = this.props.options.options.choices[status]
	    this.setState({
		status_lab : stat_lab,
		status: status
	    })
	}
	this.setState({
	    status_count :this.props.tup.status_count,
	})

    }
    handle_click_history(e:any){
	console.log('history')
	this.props.cb_parent({
	    cmd: 'quotation-history',
	    attr_id: this.props.on_attr_id,
	    //acf_key: this.props.options.options.key,
	    aspect: this.props.aspect,
	},()=>{})
    }
    public handle_value_click(e:any){
	console.log('handle-value-click')
	let lbl = this.props.label
	this.props.cb_parent({
	    cmd: 'quotation-status-vote',
	    //tup:this.props.tup,
	    label: lbl,
	    post_id : this.props.postid,
	    with_history:true,
	    history_popup: {
		aspect: this.props.aspect,
		attr_id: this.props.on_attr_id,
		post_id : this.props.postid,
		choices:this.props.options.options.choices,
		label: 'Historie',
		cmd: 'quotation-history'
	    },
	    attr_id: this.props.on_attr_id,
	    aspect: this.props.aspect,
	    acf_key_select: this.props.acfkey

	}, (r:any)=>{
	    if(r.hook === 'before_api_call'){
		this.setState({is_loading:true})
	    }else{
		console.log(r)
		this.update_choice_winner( r.winner );
	    }
	})
    }
    update_choice_winner(winner:string){
	console.log(`update winner: ${winner}`)

	let stat_lab = this.props.options.options.choices[winner]
	console.log(` lab: ${stat_lab}`)
	if(stat_lab){
	    this.setState({
		is_loading:false,
		status_lab : stat_lab,
		status:winner
	    })
	}

    }
    public handle_comment_click(e:any){
	// open the vote-popup for comments
	let lbl = this.props.label
	this.props.cb_parent({
	    cmd: 'quotations-comment-vote',
	    //tup:this.props.tup,
	    label: lbl,
	    attr_id: this.props.on_attr_id,
	    with_history:true,
	    post_id : this.props.postid,
	    history_popup: {
		aspect: this.props.aspect,
		post_id : this.props.postid,
		attr_id: this.props.on_attr_id,
		choices:this.props.options.options.choices,
		votetype:'comment',
		label: 'Historie',
	    },
	    acf_key: this.props.acfkey,
	    top3:[],
	    aspect: this.props.aspect
	}, (r:any)=>{
	    if(r.hook === 'before_api_call'){
		this.setState({is_loading:true})
	    }else{
		//this.setState({comment: r.winner_txt, is_loading:false})
		console.log('set comments?')
		if(r.new_state){
		    this.setState({
			comments: r.new_state.map((x:any)=>x.value),
			is_loading:false
		    })
		}
	    }
	})
    }


    render_comments(has_edit_perm:boolean){
	let leeg_txt = "(zet hier je opmerkingen)"
	if(this.props.aspect === "voorstel"){
	    leeg_txt = "(Leverancier kan hier opmerkingen zetten)"
	}

	
	if(this.state.comments.length==0 && has_edit_perm){
	    return (<div className="text empty"><i>{leeg_txt}</i></div>)
	}
	
	return (<div className="text">
				{this.state.comments.map((txt:string,j:number)=> {
				    let k = `t-${j}`
				    return (<div key={k} className="part" >{txt}</div>)
				})}
		</div>)
    }
    render_cdropdown(){

	let showall = true

	// filter to skip the option that is the current winner
	//    FIXME: je moet hier juist de optie weghalen van jouw eigen keuze
	let filter = (key:string) => {
	    return key !== this.state.status
	}

	/*
	if(! is_lid ) {
	    filter = (key:string) => {
		return ['aangevraagd_zw','afgewezen'].includes(key);
	    }
	}
	*/
	return (new Conditional_dropdown()).select_with_cond_logic(
	    this.props.options.buttons.buttons,
	    showall,
	    this.state.status,
	    '-kies-',
	    'Wijzig dit in:',
	    this.handle_dropdown_change,
	    filter
	)
    }
    handle_dropdown_change(e:any){
	console.log('dropdown change status-change')
	let slug = e.target.value
	e.target.value = ''
	console.log(`changed dropdown to: ${slug}`)
	let d = {
	    post_id : this.props.postid,
	    votetype: 'choice-on-attr',
	    value: slug,
	    acf_key: this.props.acfkey,
	    aspect: this.props.aspect,
	    is_final_field: "false",
	    trigger_recalc: "false",
	    on_attr_id: this.props.on_attr_id,
	}
	console.log('set is loading true')
	this.setState({ is_loading:true})
	this.props.cb_parent(d,(r:any)=>{
	    this.update_choice_winner( r.winner)
	    this.props.cb_parent({
		cmd: 'set-quotation-status',
		aspect: this.props.aspect,
		winner: r.winner
	    },()=>{})
	    
	})

    }
    get_span_lab_val(label:string, value:string){
	if(value === ''){
	    return (<span> {label}: <b><i>(nog leeg)</i></b></span>)
	}else{
	    return (<span> {label}: <b>{value}</b></span>)
	}
    }
    get_value_text(){
	let value = this.state.status === 'kies' ? '' :  this.state.status_lab
	if(this.props.aspect === "huidig"){
	    return this.get_span_lab_val('Huidige status', value)
	}else{
	    return this.get_span_lab_val('Volgens Leverancier', value)
	}
    }
    

    render(){
	var loader = (<Loader/>)
	var aspect = this.props.aspect
	var label = this.labels[aspect]




	let has_edit_perm = false
	if(this.props.permission && this.props.permission.edit){
	    has_edit_perm = this.props.permission.edit.current_user
	}
	if(this.props.readonly){
	    has_edit_perm = false
	}

	/*
		<div className="header">
		    <div className="label"> {label} </div>
		    <span data-balloon="Toon historie"
			  data-balloon-pos="up" className="cf-icon-btn"
			  onClick={this.handle_click_history}><i className="bb-icon-l bb-icon-list"/></span>
		</div>

*/

    	return (
	    <div className="aspect panel">
		{this.state.is_loading ? loader : null }
		<div className="body">
		<div className="status-fields">
		    {has_edit_perm ? (
		    <div className="collab-value winner read-write" onClick={this.handle_value_click}>
			<div className="label">
			    {this.get_value_text()}
			</div>
			<span className="cf-count-btn"
			      data-balloon="Toon historie en details" data-balloon-pos="up">
			    {this.state.status_count}
			</span>
		    </div> ) : (
			<div className="readonly winner">
			    <div className="label">
				{this.get_value_text()}
			    </div>
			</div>
		    )}
		<div className="action-buttons">
		{has_edit_perm ? this.render_cdropdown() : null}
	    </div>
		</div>
		
		<div className="remarks">
		    {has_edit_perm ? (
		    <div className="text-with-btn" onClick={this.handle_comment_click}>
			{this.render_comments(has_edit_perm)}
	    		<span className="cf-count-btn"
			      data-balloon="Toon alle opmerkingen" data-balloon-pos="up"> {this.state.comment_count}</span>
		    </div>
		    ):(
			this.render_comments(has_edit_perm)
		    )}
		</div>
		</div>
		</div>
	)
    }

}

export default QuotationStatus
